<template>
    <BT-Blade-Items
        v-if="mLocationID != null"
        @sourceChanged="updateBlade"
        addBladeName="stock-threshold"
        bladeName="stock-thresholds"
        :bladesData="bladesData"
        canAdd
        canDelete
        canExportCSV
        :getParams="getParams"
        :headers="[
            { text: 'Location', value: 'location', display: true, csv: true, subtitle: 0 },
            { text: 'Product', value: 'product', display: true, csv: true, title: 1 },
            { text: 'Min', value: 'lowerThreshold', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Min: ' },
            { text: 'Max', value: 'upperThreshold', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Max: ' }]"
        navigation="stock-thresholds"
        :onPullSuccessAsync="filterByProductsAsync"
        title="Stock Thresholds">
        <template v-slot:toolbar>
            <v-toolbar-title>
                <BT-Entity
                    navigation="locations"
                    :itemValue="mLocationID"
                    itemText="locationName">
                    <template v-slot:append><span>: Thresholds</span></template>
                </BT-Entity>
            </v-toolbar-title>
        </template>
        <template v-slot:location="{ item }">
            <BT-Entity
                navigation="locations"
                :itemValue="item.locationID"
                itemText="locationName" />
        </template>
        <template v-slot:product="{ item }">
            <BT-Entity
                navigation="products"
                :itemValue="item.productID"
                itemText="productName" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Stock-Thresholds-Blade',
    data: function() {
        return {
            mLocationID: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    async mounted() {
        if (this.mLocationID == null) {
            this.mLocationID = this.selectedLocationID();

            if (this.mLocationID == null) {
                this.mLocationID = await this.$selectItem({ 
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id', 
                    required: true
                });
            }
        }
    },
    methods: {
        getParams(bladeData) {
            return {
                includeDetails: false,
                locationID: this.mLocationID || bladeData.data.locationID
            };
        },
        async filterByProductsAsync(list) {
            var products = await this.$BlitzIt.store.getAll('products');
            list.forEach(x => {
                var p = products.find(y => y.id == x.productID);
                x.sortNumber = p != null ? p.sortNumber : 0;
            })
            return list.orderBy('sortNumber');
        },
        updateBlade(bladeData) {
            if (bladeData != null) {
                if (bladeData.data.locationID != null) {
                    this.mLocationID = bladeData.data.locationID;
                }
            }
        }
    }
}
</script>